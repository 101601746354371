<template>
  <div>
    <d-body-top-bar
      :title="$t('views.marketing.comm-marketing')"
    >
      <div slot="tree">
        <label
          class="bg-doinsport-primary ml-1"
        >
          {{ $t('views.marketing.recap-compaign') }}
        </label>
      </div>
    </d-body-top-bar>
    <div class="container-fluid">
      <b-card v-if="campaign">
        <top-content
          :campaign="campaign"
          :route-name="routeName"
        />
        <resume-content
          :email-campaign="campaign"
          class="mt-3"
        />
      </b-card>
    </div>
  </div>
</template>
<script>

import {getEmailCampaign} from "@api/doinsport/services/campaign/email-campaign.api";
import {getSmsCampaign} from "@api/doinsport/services/campaign/sms-campaign.api";
import {getPushCampaign} from "@api/doinsport/services/campaign/push-campaign.api";

export default {
  data: () => ({
    campaign: null,
  }),
  components: {
    TopContent: () => import('./TopContent'),
    ResumeContent: () => import('./ResumeContent')
  },
  computed: {
    routeName() {
      return this.$route.name;
    }
  },
  methods: {
    getCampaign() {
      const campaignId = this.$route.params.id;

      switch (this.routeName) {
        case 'campaign_email_report':
          return getEmailCampaign(campaignId);
        case 'campaign_sms_report':
          return getSmsCampaign(campaignId);
        case 'campaign_push_report':
          return getPushCampaign(campaignId);
        default:
          console.error('unknown report page', this.routeName);
      }
    },
    loadCampaign() {
      this.getCampaign()
        .then((response) => {
          this.campaign = response.data;
        })
      ;
    }
  },
  created() {
    this.loadCampaign();
  }
}
</script>
<style lang="scss" scoped>
@import "@lazy/_b-card.scss";
</style>
