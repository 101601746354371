import {getEmailCampaigns} from "@api/doinsport/services/campaign/email-campaign.api";
import {getSmsCampaigns} from "@api/doinsport/services/campaign/sms-campaign.api";
import {getPushCampaigns} from "@api/doinsport/services/campaign/push-campaign.api";
import {_put} from "@api/doinsport/services/httpService";

export const getCampaignList = (name, params) => {
  switch (name) {
    case 'email':
      return getEmailCampaigns(params.itemsPerPage, params.page, params.search, params.status);
    case 'sms':
      return getSmsCampaigns(params.itemsPerPage, params.page, params.search, params.status);
    case 'push':
      return getPushCampaigns(params.itemsPerPage, params.page, params.search, params.status);
    default:
      console.error('unknown campaign target');
  }
};

export const putCampaignStatus = (name, params) => {
  let endPoint = '/campaigns/';

  switch (name) {
    case 'email':
      endPoint += 'emails/';
      break;
    case 'sms':
      endPoint += 'sms/';
      break;
    case 'push':
      endPoint += 'pushes/'
      break;
    default:
      console.error('unknown campaign target');
  }

  return _put(endPoint + params.id, {status: params.status});
};


