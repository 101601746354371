import { URL } from './index'
import {ALL} from "@/classes/doinsport/Campaign";
import {_get, _post, _put, clubId} from "@api/doinsport/services/httpService";

const URI = '/campaigns/pushes';

export const getPushCampaigns = (itemsPerPage = 10, page = 1, search = '', status = ALL) => {
  const filter = status === ALL ? '' : '&status=' + status;

  return _get(
    URI +
    '?club.id=' + clubId +
    '&itemsPerPage='+ itemsPerPage +
    '&page=' + page +
    '&search=' + search +
    filter
  );
}
export const putPushCampaign = (data) => _put(URI + '/' + data.id, data);

export const putPushCampaignStatus = (data) => _put(URI + '/' + data.id, { status: data.status });

export const getPushCampaign = (id) => _get(URI + '/' +id);

export const postPushCampaign = (data) => _post(URI , data);
