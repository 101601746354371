import {_get, _post, _put, clubId} from "@api/doinsport/services/httpService";
import {ALL} from "@/classes/doinsport/Campaign";

const URI = '/campaigns/sms';

export const getSmsCampaigns = (itemsPerPage = 10, page = 1, search = '', status = ALL) => {
  const filter = status === ALL ? '' : '&status=' + status;

  return _get(
    URI +
    '?club.id=' + clubId +
    '&itemsPerPage=' + itemsPerPage +
    '&page=' + page +
    '&search=' + search +
    filter
  );
}
export const putSmsCampaign = (data) => _put(URI + '/' + data.id, data);

export const putSmsCampaignStatus = (data) => _put(URI + '/' + data.id, {status: data.status});

export const getSmsCampaign = (id) => _get(URI + '/' + id);

export const postSmsCampaign = (data) => _post(URI, data);
